.leaderboard-heading{
    /* display: flex;
    flex-wrap: wrap; */
    justify-content: space-between;
    align-items: flex-end;
    column-gap: 12vw;
    margin-top: 10px;
    margin-bottom: 20px;
}

.leaderboard-title {
    text-align: center;
    color: var(--secondary-color);
    background-color: var(--primary-color);
    font-size: 55px;
    margin-bottom:3px;
    font-family: 'Times New Roman', Times, serif;
    transform: scale(0.85,1);
}

.leaderboard-list {
    margin-top:10px;
    margin-bottom:20px;
}

.leaderboard-tile {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding:5px;
}

.leaderboard-rank {
    flex:2;
    text-align: center;
}

.leaderboard-player {
    flex:3;
    text-align:center;
    cursor: pointer;
}

.leaderboard-edit-player {
    position: absolute;
}

.leaderboard-levels-solved {
    flex:2;
    text-align:center;
}

.leaderboard-score {
    flex:2;
    text-align: center;
}

.leaderboard-alias-edit {
    flex:0.5;
    text-align: center;
}

.leaderboard-edit-icon {
    cursor: pointer;
}

.leaderboard-header {
    font-weight: bold;
    font-size: 18px;
    white-space: nowrap;
}

.leaderboard-body {
    font-family: Helvetica, sans-serif;
}

.leaderboard-pagination-container {
   display: flex;
   justify-content: space-between;
   margin:30px 0px;
   width:500px;
}

.leaderboard-outer-container {
    display: flex;
    justify-content: center;
}

.leaderboard-pagination-container li {
   float: left;
   list-style-type: none;
}

.leaderboard-pagination-page-item {
    padding:0px 10px;
    text-decoration: none;
    color: var(--secondary-color);
    font-weight:lighter;
}

.leaderboard-pagination-page-item:hover {
    color: var(--secondary-color);
}

.leaderboard-pagination-selected-page-item {
    text-decoration: underline;
    font-weight: bold;
    color: var(--secondary-color);
}

.leaderboard-pagination-selected-page-item:hover {
    color: var(--secondary-color);
}

.leaderboard-button-link {
    background-color: var(--secondary-color);
    padding:7px;
    color: var(--primary-color);;
    text-decoration: none;
    border-radius: 3px;
}

.leaderboard-button-link:hover {
   text-decoration: none;
   color: var(--primary-color);
}

.leaderboard-search {
    width:1000%;
}

.leaderboard-search-outer-container {
    display: flex;
    justify-content: center;
}

.leaderboard-list-container {
    min-height:500px;
    background-color: var(--primary-color);
    color: var(--secondary-color);
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    padding-bottom: 20px;
    padding-top: 10px;
}

.leaderboard-search {
    width:90%;
}
.leaderboard-alias-modal {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0,0,0,0.5);
    z-index: 100;
}

.leaderboard-modal-body {
    background-color: var(--primary-color);
    padding:40px;
    color: var(--secondary-color);
    border-radius: 10px;
    box-shadow: 0px 0px 20px black;
    min-width: 500px;
    position: relative;
}

.leaderboard-close-button {
    position:absolute;
    top:0;
    right:0;
    padding:5px;
    padding-right:10px;
    font-size: 16px;
    cursor: pointer;
}

.alias-submission-form input {
    background-color: red !important;
}

@media only screen and (max-width: 600px) {
    .leaderboard-pagination-container {
        width: 100%;
    }
    .leaderboard-outer-container {
        display: flex;
        justify-content: center;
    }
    .leaderboard-search {
        width:100%;
    }
}